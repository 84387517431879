import { useState } from "react";

const controlVersion = "control"

export const validateZipCodeInput = data => {
    // Regex to test if the key is number
    const keyregex = new RegExp("\\d$");
    // Regex to test if the value is number
    const zipcodeRegex = new RegExp("\\d{5,6}$");
    if((data.keyCode != 8 && data.keyCode != 13) && !((data.keyCode == 65 || data.keyCode == 86 || data.keyCode == 67) && (data.ctrlKey === true || data.metaKey === true)) && !(data.keyCode >= 35 && data.keyCode <= 40) && !keyregex.test(data.key) && !zipcodeRegex.test(data.target.value)) {
        data.nativeEvent.preventDefault();
    }
};
export const allowOnlyDigit = data =>{
    // Regex to test if the key is number
    const keyregex = new RegExp("[0-9()\+]$");
    // Regex to test if the value is number
    const zipcodeRegex = new RegExp("/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i");
    if((data.keyCode != 8 && data.keyCode != 13) && !((data.keyCode == 65 || data.keyCode == 86 || data.keyCode == 67) && (data.ctrlKey === true || data.metaKey === true)) && !(data.keyCode >= 35 && data.keyCode <= 40) && !keyregex.test(data.key) && !zipcodeRegex.test(data.target.value)) {
        data.nativeEvent.preventDefault();
    }
}

export function scroll(ref) {
    ref.current.scrollIntoView({behavior: 'smooth'})
}

export function getFromLocal(key) {
    try{
        return JSON.parse(window.localStorage.getItem(key))
    }catch(e){
        return null
    }
    
}

export function scrollToTop(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export const formatPrice = (price)=>{
    return parseFloat(price).toLocaleString(undefined, {maximumFractionDigits: 0})
}

export function getQueryParam(params) {
    if(!params){
        params = new URLSearchParams(window.location.search);
    }
    params.delete('make');
    params.delete('model');
    params.delete('year');
    params.delete('trim');
    params.delete('oem');
    params.delete('id');
    params.delete('skipForm');
    params.delete('step');
    params.delete('zip');
    return params.toString()
}

export const storeInLocal = (key,data) => {
    data = JSON.stringify(data);
    window.localStorage.setItem(key,data);
}

export function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


export function getTemplateId(){
    const params = new URLSearchParams(window.location.search);
    try{
        return params.get('tid')?atob(params.get('tid')):controlVersion;
    }catch(e){
        return params.get('tid') || controlVersion;
    }
}

export function fetchStateCode(phone){
    const temp = /[0-9]{3}/.exec(phone);
    return temp && temp.length?temp[0]:null;
}

export function isMobile(){
    return window.innerWidth <= 760;
}

export function isTablet(){
    return !isMobile() && window.innerWidth <= 1024;
}

export function filterBlackList(list = []){
    if(list && list.length){
        return list.filter(_=>whiteListSuggestions.indexOf(_.model.toLowerCase()) > -1);
    }
    return []
}


export function stateCodeMatch(state,phone){
    const areaCode = fetchStateCode(phone);
    if(state && phone && areaCode){
        return stateArea[areaCode] === state.toUpperCase()?"yes":"no";
    }
    // If either of state, phone or areacode is not present, then returning a neutral answer.
    return "-"
}

export function LCSubStr( X,  Y ) {
    const m = X.length;
    const n = Y.length;
    // Create a table to store
    // lengths of longest common
    // suffixes of substrings.
    // Note that LCSuff[i][j]
    // contains length of longest
    // common suffix of
    // X[0..i-1] and Y[0..j-1].
    // The first row and first
    // column entries have no
    // logical meaning, they are
    // used only for simplicity of program
     
    var LCStuff =
    Array(m + 1).fill().map(()=>Array(n + 1).fill(0));

    // To store length of the longest
    // common substring
    var result = 0;

    // Following steps build
    // LCSuff[m+1][n+1] in bottom up fashion
    for (let i = 0; i <= m; i++) {
        for (let j = 0; j <= n; j++) {
            if (i == 0 || j == 0)
                LCStuff[i][j] = 0;
            else if (X[i - 1] == Y[j - 1]) {
                LCStuff[i][j] = LCStuff[i - 1][j - 1] + 1;
                result = Math.max(result, LCStuff[i][j]);
            } else
                LCStuff[i][j] = 0;
        }
    }
    return result;
}




const stateArea = {
    
    201 :	"NJ",
    202 :	"DC",
    203 :	"CT",
    204 :	"MB",
    205 :	"AL",
    206 :	"WA",
    207 :	"ME",
    208 :	"ID",
    209 :	"CA",
    210 :	"TX",
    212 :	"NY",
    213 :	"CA",
    214 :	"TX",
    215 :	"PA",
    216 :	"OH",
    217 :	"IL",
    218 :	"MN",
    219 :	"IN",
    224 :	"IL",
    225 :	"LA",
    226 :	"ON",
    228 :	"MS",
    229 :	"GA",
    231 :	"MI",
    234 :	"OH",
    239 :	"FL",
    240 :	"MD",
    242 :	"Bahamas",
    246 :	"Barbados",
    248 :	"MI",
    250 :	"BC",
    251 :	"AL",
    252 :	"NC",
    253 :	"WA",
    254 :	"TX",
    256 :	"AL",
    260 :	"IN",
    262 :	"WI",
    264 :	"Anguilla",
    267 :	"PA",
    268 :	"Antigua and Barbuda",
    269 :	"MI",
    270 :	"KY",
    276 :	"VA",
    281 :	"TX",
    284 :	"British Virgin Islands",
    289 :	"ON",
    301 :	"MD",
    302 :	"DE",
    303 :	"CO",
    304 :	"WV",
    305 :	"FL",
    306 :	"SK",
    307 :	"WY",
    308 :	"NE",
    309 :	"IL",
    310 :	"CA",
    312 :	"IL",
    313 :	"MI",
    314 :	"MO",
    315 :	"NY",
    316 :	"KS",
    317 :	"IN",
    318 :	"LA",
    319 :	"IA",
    320 :	"MN",
    321 :	"FL",
    323 :	"CA",
    325 :	"TX",
    330 :	"OH",
    334 :	"AL",
    336 :	"NC",
    337 :	"LA",
    339 :	"MA",
    340 :	"USVI",
    345 :	"Cayman Islands",
    347 :	"NY",
    351 :	"MA",
    352 :	"FL",
    360 :	"WA",
    361 :	"TX",
    386 :	"FL",
    401 :	"RI",
    402 :	"NE",
    403 :	"AB",
    404 :	"GA",
    405 :	"OK",
    406 :	"MT",
    407 :	"FL",
    408 :	"CA",
    409 :	"TX",
    410 :	"MD",
    412 :	"PA",
    413 :	"MA",
    414 :	"WI",
    415 :	"CA",
    416 :	"ON",
    417 :	"MO",
    418 :	"QC",
    419 :	"OH",
    423 :	"TN",
    424 :	"CA",
    425 :	"WA",
    430 :	"TX",
    432 :	"TX",
    434 :	"VA",
    435 :	"UT",
    438 :	"QC",
    440 :	"OH",
    441 :	"Bermuda",
    443 :	"MD",
    450 :	"QC",
    456 :	"Reserved",
    469 :	"TX",
    473 :	"Grenada",
    478 :	"GA",
    479 :	"AR",
    480 :	"AZ",
    484 :	"PA",
    500 :	"Reserved",
    501 :	"AR",
    502 :	"KY",
    503 :	"OR",
    504 :	"LA",
    505 :	"NM",
    506 :	"NB",
    507 :	"MN",
    508 :	"MA",
    509 :	"WA",
    510 :	"CA",
    512 :	"TX",
    513 :	"OH",
    514 :	"QC",
    515 :	"IA",
    516 :	"NY",
    517 :	"MI",
    518 :	"NY",
    519 :	"ON",
    520 :	"AZ",
    530 :	"CA",
    540 :	"VA",
    541 :	"OR",
    551 :	"NJ",
    559 :	"CA",
    561 :	"FL",
    562 :	"CA",
    563 :	"IA",
    567 :	"OH",
    570 :	"PA",
    571 :	"VA",
    573 :	"MO",
    574 :	"IN",
    580 :	"OK",
    585 :	"NY",
    586 :	"MI",
    600 :	"Reserved",
    601 :	"MS",
    602 :	"AZ",
    603 :	"NH",
    604 :	"BC",
    605 :	"SD",
    606 :	"KY",
    607 :	"NY",
    608 :	"WI",
    609 :	"NJ",
    610 :	"PA",
    612 :	"MN",
    613 :	"ON",
    614 :	"OH",
    615 :	"TN",
    616 :	"MI",
    617 :	"MA",
    618 :	"IL",
    619 :	"CA",
    620 :	"KS",
    623 :	"AZ",
    626 :	"CA",
    630 :	"IL",
    631 :	"NY",
    636 :	"MO",
    641 :	"IA",
    646 :	"NY",
    647 :	"ON",
    649 :	"Turks & Caicos Islands",
    650 :	"CA",
    651 :	"MN",
    660 :	"MO",
    661 :	"CA",
    662 :	"MS",
    664 :	"Montserrat",
    670 :	"MP",
    671 :	"GU",
    678 :	"GA",
    682 :	"TX",
    684 :	"AS",
    700 :	"Reserved",
    701 :	"ND",
    702 :	"NV",
    703 :	"VA",
    704 :	"NC",
    705 :	"ON",
    706 :	"GA",
    707 :	"CA",
    708 :	"IL",
    709 :	"NL",
    710 :	"US Government",
    712 :	"IA",
    713 :	"TX",
    714 :	"CA",
    715 :	"WI",
    716 :	"NY",
    717 :	"PA",
    718 :	"NY",
    719 :	"CO",
    720 :	"CO",
    724 :	"PA",
    727 :	"FL",
    731 :	"TN",
    732 :	"NJ",
    734 :	"MI",
    740 :	"OH",
    754 :	"FL",
    757 :	"VA",
    758 :	"St. Lucia",
    760 :	"CA",
    762 :	"GA",
    763 :	"MN",
    765 :	"IN",
    767 :	"Dominica",
    769 :	"MS",
    770 :	"GA",
    772 :	"FL",
    773 :	"IL",
    774 :	"MA",
    775 :	"NV",
    778 :	"BC",
    780 :	"AB",
    781 :	"MA",
    784 :	"St. Vincent & Grenadines",
    785 :	"KS",
    786 :	"FL",
    787 :	"PR",
    800 :	"Toll Free",
    801 :	"UT",
    802 :	"VT",
    803 :	"SC",
    804 :	"VA",
    805 :	"CA",
    806 :	"TX",
    807 :	"ON",
    808 :	"HI",
    809 :	"Dominican Republic",
    810 :	"MI",
    812 :	"IN",
    813 :	"FL",
    814 :	"PA",
    815 :	"IL",
    816 :	"MO",
    817 :	"TX",
    818 :	"CA",
    819 :	"QC",
    828 :	"NC",
    829 :	"Dominican Republic",
    830 :	"TX",
    831 :	"CA",
    832 :	"TX",
    843 :	"SC",
    845 :	"NY",
    847 :	"IL",
    848 :	"NJ",
    850 :	"FL",
    856 :	"NJ",
    857 :	"MA",
    858 :	"CA",
    859 :	"KY",
    860 :	"CT",
    862 :	"NJ",
    863 :	"FL",
    864 :	"SC",
    865 :	"TN",
    866 :	"Toll Free",
    867 :	"YT",
    868 :	"Trinidad & Tobago",
    869 :	"St. Kitts & Nevis",
    870 :	"AR",
    876 :	"Jamaica",
    877 :	"Toll Free",
    878 :	"PA",
    888 :	"Toll Free",
    900 :	"Toll Calls",
    901 :	"TN",
    902 :	"NS",
    903 :	"TX",
    904 :	"FL",
    905 :	"ON",
    906 :	"MI",
    907 :	"AK",
    908 :	"NJ",
    909 :	"CA",
    910 :	"NC",
    912 :	"GA",
    913 :	"KS",
    914 :	"NY",
    915 :	"TX",
    916 :	"CA",
    917 :	"NY",
    918 :	"OK",
    919 :	"NC",
    920 :	"WI",
    925 :	"CA",
    928 :	"AZ",
    931 :	"TN",
    936 :	"TX",
    937 :	"OH",
    939 :	"PR",
    940 :	"TX",
    941 :	"FL",
    947 :	"MI",
    949 :	"CA",
    951 :	"CA",
    952 :	"MN",
    954 :	"FL",
    956 :	"TX",
    970 :	"CO",
    971 :	"OR",
    972 :	"TX",
    973 :	"NJ",
    978 :	"MA",
    979 :	"TX",
    980 :	"NC",
    985 :	"LA",
    989 :	"MI",
    442 :	"CA",
    628 :	"CA",
    575 :	"NM",
    737 :	"TX",
    346 :	"TX",
    470 :	"GA",
    929 :	"NY",
    223 :	"PA",
    669 :	"CA",
    681 :	"WV",
    555 :	"Tollfree",
    331 :	"IL",
    539 :	"OK",
    873 :	"QC",
    343 :	"ON",
    475 :	"CT",
    911 :	"Emergency",
    779 :	"IL",
    639 :	"SK",
    725 :	"NV",
    548 :	"ON",
    984 :	"NC",
    657 :	"CA",
    385 :	"UT",
    283 :	"OH",
    458 :	"OR",
    747 :	"CA",
    872 :	"IL",
    311 :	"Tollfree",
    629 :	"TN",
    272 :	"PA",
    579 :	"QC",
    959 :	"CT",
    855 :	"Tollfree",
    564 :	"WA",
    833 :	"Tollfree",
    667 :	"MD",
    844 :	"Tollfree",
    220 :	"OH",
}

// Black list of models which shouldn't appear in suggestions, all in small case please.
export const whiteListSuggestions = [
    "xt4",
    "silverado 2500hd",
    "suburban",
    "tahoe",
    "sierra 1500",
    "sierra 2500hd",
    "sierra 3500hd",
    "sierra 3500hd cc",
    "yukon",
    "yukon xl",
]

export const UsStatesMasterList = [
    {
        "name": "Alabama",
        "value": "Alabama"
    },
    {
        "name": "Alaska",
        "value": "Alaska"
    },
    {
        "name": "American Samoa",
        "value": "American Samoa"
    },
    {
        "name": "Arizona",
        "value": "Arizona"
    },
    {
        "name": "Arkansas",
        "value": "Arkansas"
    },
    {
        "name": "California",
        "value": "California"
    },
    {
        "name": "Colorado",
        "value": "CO"
    },
    {
        "name": "Connecticut",
        "value": "CT"
    },
    {
        "name": "Delaware",
        "value": "DE"
    },
    {
        "name": "District Of Columbia",
        "value": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "value": "FM"
    },
    {
        "name": "Florida",
        "value": "FL"
    },
    {
        "name": "Georgia",
        "value": "GA"
    },
    {
        "name": "Guam",
        "value": "GU"
    },
    {
        "name": "Hawaii",
        "value": "HI"
    },
    {
        "name": "Idaho",
        "value": "ID"
    },
    {
        "name": "Illinois",
        "value": "IL"
    },
    {
        "name": "Indiana",
        "value": "IN"
    },
    {
        "name": "Iowa",
        "value": "IA"
    },
    {
        "name": "Kansas",
        "value": "KS"
    },
    {
        "name": "Kentucky",
        "value": "KY"
    },
    {
        "name": "Louisiana",
        "value": "LA"
    },
    {
        "name": "Maine",
        "value": "ME"
    },
    {
        "name": "Marshall Islands",
        "value": "MH"
    },
    {
        "name": "Maryland",
        "value": "MD"
    },
    {
        "name": "Massachusetts",
        "value": "MA"
    },
    {
        "name": "Michigan",
        "value": "MI"
    },
    {
        "name": "Minnesota",
        "value": "MN"
    },
    {
        "name": "Mississippi",
        "value": "MS"
    },
    {
        "name": "Missouri",
        "value": "MO"
    },
    {
        "name": "Montana",
        "value": "MT"
    },
    {
        "name": "Nebraska",
        "value": "NE"
    },
    {
        "name": "Nevada",
        "value": "NV"
    },
    {
        "name": "New Hampshire",
        "value": "NH"
    },
    {
        "name": "New Jersey",
        "value": "NJ"
    },
    {
        "name": "New Mexico",
        "value": "NM"
    },
    {
        "name": "New York",
        "value": "NY"
    },
    {
        "name": "North Carolina",
        "value": "NC"
    },
    {
        "name": "North Dakota",
        "value": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "value": "MP"
    },
    {
        "name": "Ohio",
        "value": "OH"
    },
    {
        "name": "Oklahoma",
        "value": "OK"
    },
    {
        "name": "Oregon",
        "value": "OR"
    },
    {
        "name": "Palau",
        "value": "PW"
    },
    {
        "name": "Pennsylvania",
        "value": "PA"
    },
    {
        "name": "Puerto Rico",
        "value": "PR"
    },
    {
        "name": "Rhode Island",
        "value": "RI"
    },
    {
        "name": "South Carolina",
        "value": "SC"
    },
    {
        "name": "South Dakota",
        "value": "SD"
    },
    {
        "name": "Tennessee",
        "value": "TN"
    },
    {
        "name": "Texas",
        "value": "TX"
    },
    {
        "name": "Utah",
        "value": "UT"
    },
    {
        "name": "Vermont",
        "value": "VT"
    },
    {
        "name": "Virgin Islands",
        "value": "VI"
    },
    {
        "name": "Virginia",
        "value": "VA"
    },
    {
        "name": "Washington",
        "value": "WA"
    },
    {
        "name": "West Virginia",
        "value": "WV"
    },
    {
        "name": "Wisconsin",
        "value": "WI"
    },
    {
        "name": "Wyoming",
        "value": "WY"
    }
]

export const addToDataLayer = (obj) => {
    window.dataLayer?.push(obj ?? {})
}
export const fireGTMPixel = ({ eventName = "element-click", label}) => {
    window.dataLayer?.push({
        'event': eventName,
        'event_value': label
    })
}

export const fireMaxPixel = (dataObj) => {
    let {label} = dataObj;
    fireGTMPixel({eventName: 'record-max-pixel',label: label})
}

export const firePixel = (dataObj) => {
    fireGTMPixel(dataObj)
    fireMaxPixel(dataObj)
}

export const fireAutowebPixel = () => {
    const img = document.createElement('img');
    img.src = "//evt.awadserver.com/ConversionManager?conversionData=eyJjb252ZXJzaW9uVHlwZSI6IkxlYWQiLCJhZHZlcnRpc2VySUQiOjMyMjR9";
    img.width = 1;
    img.height = 1;
    img.style.display = 'none'; // Optionally hide the image
    document.body.appendChild(img);
}

export const fireAgeEnvyScript = () => {
    (function(s,a,e,v,n,t,z){if(s.saq)return;n=s.saq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';n.queue=[];t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];z.parentNode.insertBefore(t,z)})(window,document,'script','https://tags.srv.stackadapt.com/events.js');window.saq('ts', 'Tl0eaPCfAnEVesmr7UCu0g');
}

export const fireAdTargetScript = () => {
    (function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)})(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    window.fbq('init', '1179234793269016');
    window.fbq('track', 'Lead');
}